<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card class="text-center pt-10" tile>
      <h1 class="font-weight-black text-h5 mb-10">CONFIRMAR PLAN MÓVIL</h1>
      <div class="mb-3 pa-0">
        <icon-internet-movil class="icon--internet-movil"></icon-internet-movil>
      </div>
      <div>
        <p class="text-subtitle-2 font-weight-regular mb-2">Cliente</p>
        <p class="font-weight-black text-uppercase">
          {{ purchase.idClient.name }}
        </p>
      </div>
      <div>
        <p class="text-subtitle-2 font-weight-regular mb-2">No. de teléfono</p>
        <p class="font-weight-black text-uppercase">
          {{ purchase.idClient.movilNumber }}
        </p>
      </div>
      <div>
        <p class="text-subtitle-2 font-weight-regular mb-2">Vigencia</p>
        <p class="font-weight-black text-uppercase">
          {{ purchase.days }} DÍAS
        </p>
      </div>
      <div>
        <p class="text-subtitle-2 font-weight-regular mb-2">Costo</p>
        <p class="font-weight-black text-uppercase">{{ purchase.price }}</p>
      </div>
      <div>
        <p class="text-subtitle-2 font-weight-regular mb-2">Estado de pago</p>
        <p class="font-weight-black text-uppercase">{{ purchase.status }}</p>
      </div>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-space-between pt-5 pb-5">
        <v-btn
          class="pl-15 pr-15"
          depressed
          large
          color="error"
          @click="$emit('cancel', purchase)"
          >CANCELAR</v-btn
        >
        <v-btn
          class="pl-5 pr-5"
          depressed
          large
          color="primary"
          @click="confirm"
          >SI, CONFIRMAR PLAN</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Domains
import Purchase from "../../domain/Purchase";

export default {
  name: "InternetMovilConfirm",
  components: {
    IconInternetMovil: () => import("../icons/IconInternetMovil"),
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    purchase: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async confirm() {
      try {
        const purchaseSend = Object.assign({}, this.purchase);
        purchaseSend.idClient = purchaseSend.idClient._id;
        await Purchase.purchaseMovil(purchaseSend);
        this.$emit("saleGenerated");
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon--internet-movil {
  width: 5rem;
}
</style>